
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  watch,
} from "vue";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import Multiselect from "@vueform/multiselect";
import { getOrganization } from "@/core/services/JwtService";
import PaginationUi from "@/components/ABilling/PaginationXS.vue";
import { useRouter } from "vue-router";
import { searchDictModifiers } from "@/api/code-master-modifier.api";
import {
  getHCPCS,
  searchHCPCSs,
  updateHCPCS,
} from "@/api/code-master-hcpcs.api";
import { helpers, maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useFilters from "@/modules/common/useFilters";
import { getTypeOfBillCode } from "@/api/dictionaries.api";
import { searchDictRevenueCodes } from "@/api/code-master.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getPanel } from "@/api/encounter.api";
import { getList } from "@/api/dropdown.api";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  isDirty: boolean;
  facility?: any;
  items: any[];
  actualListInsurances: { id: string }[];
  selectedInsurance: string;
  displayInactive: boolean;
  selectedItem: any;
  previousSelectedItem: any;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  keyword?: string;
  pagination: Pagination;
  encounterTypes: { code; name }[];
  unitHCPCS: { code; name }[];
  dict: {
    facilities;
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    networkTypes: string[];
    levelOfcare: [];
  };
}
export default defineComponent({
  name: "Modifier",
  props: ["facility"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  components: { PaginationUi, Multiselect, DecimalInput },
  setup(props) {
    let organizationId = ref<string | null>("");

    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "Code",
        key: "code",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Description",
        key: "description",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Active",
        key: "isActive",
        isFilter: true,
        keyword: null,
      },
    ];

    let data = reactive<Data>({
      isDirty: false,
      items: [],
      actualListInsurances: [],
      selectedInsurance: "",
      displayInactive: false,
      selectedItem: undefined,
      previousSelectedItem: undefined,
      keyword: "",
      orderBy: "code",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      encounterTypes: [
        { code: 0, name: "Facility" },
        { code: 1, name: "Professional" },
      ],
      unitHCPCS: [
        { code: 0, name: "Days" },
        { code: 1, name: "Units" },
      ],
      dict: {
        facilities: [],
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        networkTypes: ["In Network", "Out Of Network"],
        levelOfcare: [],
      },
    });

    watch(
      () => props.facility,
      async (currentValue, oldValue) => {
        await cleanState();
        await selectFacility();
      }
    );

    watch(
      () => data.selectedItem,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousSelectedItem &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousSelectedItem)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    const ModifierRules = {
      selectedItem: {
        description: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(275),
        },
        // shortDescription: { maxLength: maxLength(150) },
        duration: { maxLength: maxLength(10) },
        // effectiveDate: { required: helpers.withMessage("Required", required) },
      },
    };

    let v$ = useVuelidate(ModifierRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;

      const typeOfBillCodes = await getTypeOfBillCode();

      data.dict.typeOfFacility = typeOfBillCodes.filter((item) => {
        return item.level == 1;
      });
      data.dict.typeOfCare = typeOfBillCodes.filter((item) => {
        return item.level == 2;
      });
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });

      data.dict.levelOfcare = await getList({ type: "Level Of Care" });

      await getAll();

      //await getInsurances();
    });

    async function selectFacility() {
      data.keyword = undefined;
      await getAll();
    }

    async function debounceGetAll() {
      await debounce(getAll, useFilters().debounceMs)();
    }

    async function getAll() {
      if (!props.facility) return;

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        search: data.keyword,
        facilityId: props.facility,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        displayInactive: data.displayInactive,
      };
      const res = await searchHCPCSs(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      v$.value.$reset();
    }

    async function selectItem(item) {
      data.selectedItem = JSON.parse(JSON.stringify(item));
      data.previousSelectedItem = JSON.parse(JSON.stringify(item));
    }

    async function updateItem() {
      const valid = await v$.value.selectedItem.$validate();
      if (!valid) return;

      await updateHCPCS(data.selectedItem);
      const selectedItemInListIndex = data.items.findIndex((item: any) => {
        return item.id == data.selectedItem.id;
      });
      data.items[selectedItemInListIndex] = data.selectedItem;
      await getAll();
      data.selectedItem = null;
      data.previousSelectedItem = null;
      data.isDirty = false;
      // }
    }

    async function addMode() {
      v$.value.$reset();
      data.selectedItem = {
        encounterType: 0,
        typeOfFacility: null,
        typeOfCare: null,
        frequency: null,
        revenueCode: null,
        rate: null,
        hours: null,
        unitModifier: null,
        modifier: null,
        unit: null,
        minutes: null,
        effectiveDate: new Date(),
        terminationDate: null,
        billable: true,
        isActive: true,
      };
      await nextTick();
      data.previousSelectedItem = JSON.parse(JSON.stringify(data.selectedItem));
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;

      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clear() {
      if (data.isDirty) {
        let text = "Are you sure you want to leave without saving changes?";
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            await cleanState();
            data.isDirty = false;
          }
        });
      } else {
        await cleanState();
      }
    }

    async function cleanState() {
      if (data.selectedItem && data.selectedItem.id) {
        const oldItem = await getHCPCS(data.selectedItem.id);
        data.selectedItem.code = oldItem.code;
        data.selectedItem.description = oldItem.description;
        data.selectedItem.rate = oldItem.rate;
        data.selectedItem.category = oldItem.category;
        data.selectedItem.isActive = oldItem.isActive;
        data.selectedItem = null;
        data.previousSelectedItem = null;
      } else {
        data.selectedItem = null;
        data.previousSelectedItem = null;
      }
    }

    async function getRevenueCode(text) {
      const res = await searchDictRevenueCodes({
        search: text,
        facilityId: props.facility,
      });
      return res;
    }

    async function getModifiers(text) {
      const res = await searchDictModifiers({
        search: text,
        facilityId: props.facility,
      });
      return res;
    }

    const nowDate = computed(() => {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    });

    const minTermDate = computed(() => {
      if (new Date(data.selectedItem.effectiveDate) > nowDate.value)
        return data.selectedItem.effectiveDate;
      return nowDate.value;
    });

    return {
      nowDate,
      pageChanged,
      pageSizeChanged,
      organizationId,
      selectItem,
      updateItem,
      addMode,
      selectFilter,
      getSortInfo,
      orderList,
      debounceGetAll,
      getAll,
      getRevenueCode,
      getModifiers,
      selectFacility,
      minTermDate,
      clear,
      v$,
      props,
      data,
    };
  },
});

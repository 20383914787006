
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getBatchItem } from "@/api/batch.api";
import { waystarFieldsUpdate } from "@/api/claim.api";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Data {
  batchItem: any;
  isReadonly: boolean;
}
export default defineComponent({
  name: "WaystarForm",
  components: {},
  props: ["batchItem", "claimId", "isReadonly"],
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      batchItem: {},
      isReadonly: false,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      if (props.batchItem) {
        data.batchItem = props.batchItem;
      } else {
        data.batchItem = await getBatchItem({ claimId: props.claimId });
      }

      data.isReadonly = props.isReadonly;
    });

    async function save() {
      await waystarFieldsUpdate({
        claimId: data.batchItem.claimId,
        waystarFields: data.batchItem.waystarFieldsDto,
      });
      ctx.emit("claimUpdated");
      Swal.fire({
        text: "Claim details has been successfully updated!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "ab-button",
        },
      });
    }

    return {
      organizationId,
      data,
      save,
    };
  },
});

import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/insurancePaymentDistribution";

export async function searchInsurancePaymentDistributions(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function deleteForClaim(request) {
  return await ApiService.post(url + "/deleteForClaim", request as any)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getInsurancePaymentDistribution(request) {
  return await ApiService.post(
    url + "/searchPostedInsurancePaymentForClaimLineItems",
    request as never
  )
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addInsurancePaymentDistribution(payment) {
  return await ApiService.post(url, payment as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function reverseInsurancePaymentDistribution(reverseModel) {
  return await ApiService.post(url + "/reverse", reverseModel as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateInsurancePaymentDistribution(payment) {
  if (payment) {
    return await ApiService.put(url + "/" + payment.id, payment as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteInsurancePaymentDistribution(distributionId) {
  if (distributionId) {
    return await ApiService.delete(url + "/" + distributionId)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "text-danger" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  class: "row",
  style: {"min-height":"600px"}
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  type: "button",
  class: "ab-button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DecimalInput = _resolveComponent("DecimalInput")!

  return (!_ctx.data.guid)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_DecimalInput, {
            modelValue: _ctx.data.amount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.amount) = $event)),
            minValue: "0",
            precision: "2",
            valueRange: "10000000"
          }, null, 8, ["modelValue"])
        ]),
        (_ctx.data.amount && !(_ctx.data.amount < _ctx.nexioTransactionMaxValue))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, "The value must be less than " + _toDisplayString(_ctx.nexioTransactionMaxValue), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addTransaction())),
            type: "button",
            class: "ab-button",
            disabled: !_ctx.isAmountExists
          }, " Initiate Transaction ", 8, _hoisted_4)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("iframe", {
            ref: "nexio",
            id: "saveTransactionIframe",
            src: _ctx.data.url
          }, " ", 8, _hoisted_7)
        ]),
        _createElementVNode("div", null, [
          (!_ctx.data.isInProgress)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveTransactionIframe())),
                type: "button",
                class: "ab-button",
                disabled: !_ctx.data.isFormValid
              }, " Charge (" + _toDisplayString(_ctx.$filters.getCurrency(_ctx.data.amount)) + ") ", 9, _hoisted_8))
            : (_openBlock(), _createElementBlock("button", _hoisted_9, " In progress ")),
          (!_ctx.data.isInProgress)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancel())),
                type: "button",
                class: "ab-button-secondary"
              }, " Cancel "))
            : _createCommentVNode("", true)
        ])
      ]))
}

import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { getOrganization } from "@/core/services/JwtService";
import useVuelidate from "@vuelidate/core";
import { email, helpers, maxLength, required } from "@vuelidate/validators";
import { getPatient, deletePatient, updatePatient } from "@/api/patient.api";
import { Patient } from "./patient.model";
import Country from "@/components/ABilling/CountryComponent.vue";
import State from "@/components/ABilling/StateComponent.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "@vueform/multiselect";
import { useRoute, useRouter } from "vue-router";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  isDirty: boolean;
  patientPrevious?: Patient;
  patient?: Patient;
  createNewMode: boolean;
  isReady: boolean;
  isEditMode: boolean;
  dict: {
    patientSex: [];
  };
}
export default defineComponent({
  name: "PatientDemographicsComponent",
  props: ["patient", "patientId"],
  components: {
    Multiselect,
    Country,
    State,
    PhoneNumberComponent,
    DateFloatComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      const todest = to as any;
      //spec title for Back Button
      if (todest && todest.path == "/patientAccounts") {
        text = "Are you sure you want to leave without saving changes?";
      }
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      patient: undefined,
      createNewMode: true,
      isReady: false,
      isEditMode: false,
      dict: {
        patientSex: [],
      },
    });

    const route = useRoute();
    const router = useRouter();

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    data.patient = {} as Patient;

    watch(
      () => data.patient,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.patientPrevious &&
          JSON.stringify(currentValue) != JSON.stringify(data.patientPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    watch(
      () => props.patient,
      () => {
        if (props.patient) {
          data.patient = props.patient as Patient;
          data.createNewMode = false;
        }
      }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.patientSex = store.getters.allPatientSex;

      if (props.patient) {
        data.patient = props.patient as Patient;
        data.createNewMode = false;
      } else if (props.patientId) {
        data.patient = await getPatient(props.patientId);
        data.createNewMode = false;
      }

      if (data.patient) {
        data.patient.country ??= "1";
      }

      data.patientPrevious = JSON.parse(JSON.stringify(data.patient));
      data.isReady = true;
    });

    async function switchMode() {
      data.createNewMode = true;
      data.patient = {} as Patient;
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        Swal.fire({
          title: "Would you like to apply these changes to all Open Claims?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed && data.patient) {
            data.patient.updateClaimsEvent = true;
          } else if (data.patient) {
            data.patient.updateClaimsEvent = false;
          }
          data.isEditMode = false;
          await updatePatient(data.patient);
          data.patientPrevious = JSON.parse(JSON.stringify(data.patient));
          data.isDirty = false;

          if (breadcrumbPath && breadcrumbPath != "back") {
            router.push(breadcrumbPath);
          }
        });
      }
    }

    async function deleteItem() {
      await deletePatient(data.patient);
      ctx.emit("itemChanged");
      ctx.emit("switchMode");
    }

    async function cancel() {
      if (data.isDirty) {
        Swal.fire({
          title: "Are you sure you want to cancel without saving changes?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            data.patient = await getPatient(props.patientId);
            data.isEditMode = false;
            data.createNewMode = false;
            v$.value.$reset();
          }
        });
      } else {
        data.patient = await getPatient(props.patientId);
        data.isEditMode = false;
        data.createNewMode = false;
        v$.value.$reset();
      }
    }

    const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // eslint-disable-line

    const patientRules = {
      patient: {
        accountId: { required: helpers.withMessage("Required", required) },
        firstName: { required: helpers.withMessage("Required", required) },
        lastName: { required: helpers.withMessage("Required", required) },
        dob: { required: helpers.withMessage("Required", required) },
        sex: { required: helpers.withMessage("Required", required) },
        address: { required: helpers.withMessage("Required", required) },
        city: { required: helpers.withMessage("Required", required) },
        state: { required: helpers.withMessage("Required", required) },
        zip: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(15),
        },
        email: { email },
        homePhone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        officePhone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        mobilePhone: {
          required: helpers.withMessage("Required", required),
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
      },
    };

    const v$ = useVuelidate(patientRules, data as never);

    return {
      organizationId,
      data,
      updateItem,
      deleteItem,
      switchMode,
      cancel,
      v$,
    };
  },
});

import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/encounters";

export async function getPanel(includePatients = false) {
  return await ApiService.post("api/v1/encounterPanel", {
    includePatients: includePatients,
  } as any)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getEncounters() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if (item.admissionDate)
          item.admissionDate = new Date(item.admissionDate);
        if (item.dischargeDate)
          item.dischargeDate = new Date(item.dischargeDate);
        item.statementCoversPeriodFrom = new Date(
          item.statementCoversPeriodFrom
        );
        item.statementCoversPeriodTo = new Date(item.statementCoversPeriodTo);
        item.services.forEach((service: any) => {
          service.serviceDate = new Date(service.serviceDate);
        });
      });

      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getEncounter(encounterId) {
  return await ApiService.get(url + "/" + encounterId)
    .then((res) => {
      const encounter = res.data;
      //TODO DEBT
      // if (encounter.admissionDate)
      //   encounter.admissionDate = new Date(encounter.admissionDate);
      // if (encounter.dischargeDate)
      //   encounter.dischargeDate = new Date(encounter.dischargeDate);
      // if (encounter.statementCoversPeriodFrom)
      //   encounter.statementCoversPeriodFrom = new Date(
      //     encounter.statementCoversPeriodFrom
      //   );
      // if (encounter.statementCoversPeriodTo)
      //   encounter.statementCoversPeriodTo = new Date(
      //     encounter.statementCoversPeriodTo
      //   );
      // encounter.services.forEach((service: any) => {
      //   service.serviceDate = new Date(service.serviceDate);
      // });
      return encounter;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchEncounters(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      data.data.forEach((item: any) => {
        item.admissionDate = new Date(item.admissionDate);
        item.dischargeDate = new Date(item.dischargeDate);
        item.statementCoversPeriodFrom = new Date(
          item.statementCoversPeriodFrom
        );
        item.statementCoversPeriodTo = new Date(item.statementCoversPeriodTo);
        item.services.forEach((service: any) => {
          service.serviceDate = new Date(service.serviceDate);
        });
      });

      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchEncountersV2(request) {
  return await ApiService.post(url + "/searchV2", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

//getAudit
export async function getAudit(request) {
  return await ApiService.post(url + "/getAudit", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getFilters() {
  return await ApiService.post(url + "/getFilters", {})
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addEncounter(encounter) {
  return await ApiService.post(url, encounter as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addRawEncounter(encounter) {
  return await ApiService.post(url + "/RawCreate", encounter as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function postAction(encounterActionRequest) {
  return await ApiService.post(
    url + "/postAction",
    encounterActionRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function voidAction(voidEncounterRequest) {
  return await ApiService.post(
    url + "/voidAction",
    voidEncounterRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkEncounterTransactions(
  checkEncounterTransactionsRequest
) {
  return await ApiService.post(
    url + "/checkEncounterTransactions",
    checkEncounterTransactionsRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkEncountersMaxClaimAmount(
  checkEncounterMaxClaimAmountRequest
) {
  return await ApiService.post(
    url + "/CheckEncountersMaxClaimAmount",
    checkEncounterMaxClaimAmountRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkEncounterMaxClaimAmount(
  checkEncounterMaxClaimAmountRequest
) {
  return await ApiService.post(
    url + "/checkEncounterMaxClaimAmount",
    checkEncounterMaxClaimAmountRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateReasonCode(encounterId: string, reason: string) {
  return await ApiService.post(url + "/updateReasonCode", {
    id: encounterId,
    reason: reason,
  } as any)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkEncounterHasOpenClaims(
  checkEncounterHasOpenClaimsRequest
) {
  return await ApiService.post(
    url + "/checkEncounterHasOpenClaims",
    checkEncounterHasOpenClaimsRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkEncounterServicesNewBallance(
  checkEncounterServicesNewBallanceRequest
) {
  return await ApiService.post(
    url + "/checkEncounterServicesNewBallance",
    checkEncounterServicesNewBallanceRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateEncounter(encounter) {
  if (encounter) {
    await ApiService.put(url + "/" + encounter.id, encounter as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
export async function updateRawEncounter(encounter) {
  if (encounter) {
    await ApiService.put(url + "/RawUpdate/" + encounter.id, encounter as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteEncounter(encounterId) {
  if (encounterId) {
    return await ApiService.delete(url + "/" + encounterId)
      .then((res) => {
        return res.data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

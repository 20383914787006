
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  computed,
} from "vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import Multiselect from "@vueform/multiselect";
import useFilters from "../common/useFilters";
import { searchNotes, addNote, updateNote, deleteNote } from "@/api/note.api";
import { searchUsers } from "@/api/user.api";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { getNow, getUTCnow } from "@/utility";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  isLoaded: boolean;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  currentNote?: any;
  initNote?: any;
  noteStatuses: [];
  noteTypes: any[];
  mainType: number;
  users: any[];
  filter: {
    noteType?: any;
  };
}
export default defineComponent({
  name: "NotesList",
  props: [
    "patientId",
    "encounterId",
    "claimId",
    "paymentId",
    "insurancePaymentId",
  ],
  components: { PaginationUi, Multiselect, DateFloatComponent },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "User",
        key: "user",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Date",
        key: "date",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Type",
        key: "type",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Additional Information",
        key: "amountDue",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Message",
        key: "message",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Assigned To",
        key: "assignedTo",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Due Date",
        key: "dueDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Status",
        key: "status",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      orderBy: "statementId",
      IsAdvancedOrderBy: true,
      isDecr: true,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      noteStatuses: [],
      noteTypes: [],
      mainType: 0,
      users: [],
      filter: {},
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "statementId";
      data.isDecr = true;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      if (props.paymentId != null || props.insurancePaymentId != null) {
        data.mainType = 3;
        data.filter.noteType = [3];
      } else if (props.claimId != null) {
        data.mainType = 2;
        data.filter.noteType = [2];
      } else if (props.encounterId != null) {
        data.mainType = 1;
        data.filter.noteType = [1];
      } else if (props.patientId != null) {
        data.mainType = 0;
        data.filter.noteType = [0];
      }

      data.noteStatuses = store.getters.allNoteStatuses;
      data.noteTypes = store.getters.allNoteTypes;
      data.users = await searchUsers({});
      await getAll();
      data.isLoaded = true;
    });

    function availableNotesTypes() {
      let noteTypes = data.noteTypes;
      if (data.mainType == 0) return noteTypes;
      if (data.mainType == 1 || data.mainType == 2)
        return noteTypes.filter((c) => {
          return c.code != "3";
        });
      return noteTypes.filter((c) => {
        return c.code == "3";
      });
    }

    const noteRules = {
      currentNote: {
        user: { required: helpers.withMessage("Required", required) },
        message: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(500),
        },
        status: { required: helpers.withMessage("Required", required) },
        type: { required: helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(noteRules, data as never);

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      await nextTick();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        type: data.filter.noteType,
        patientId: props.patientId,
        encounterId: props.encounterId,
        claimId: props.claimId,
        paymentId: props.paymentId,
        insurancePaymentId: props.insurancePaymentId,
      };

      const res = await searchNotes(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function addCurrentNote() {
      data.currentNote = {
        user: data.users[0].id,
        type: data.mainType,
        message: null,
        assignedTo: null,
        dueDate: getUTCnow(),
        status: 0,
        patientId: data.mainType == 0 ? props.patientId : null,
        encounterId: data.mainType == 1 ? props.encounterId : null,
        claimId: data.mainType == 2 ? props.claimId : null,
        paymentId: data.mainType == 3 ? props.paymentId : null,
        insurancePaymentId:
          data.mainType == 3 ? props.insurancePaymentId : null,
      };
    }

    async function addItem() {
      const result = await v$.value.currentNote.$validate();
      if (!result) return;

      const newNote = {
        user: data.users[0].id,
        type: data.mainType,
        message: data.currentNote.message,
        assignedTo: data.currentNote.assignedTo,
        dueDate: data.currentNote.dueDate,
        status: data.currentNote.status,
        patientId: data.currentNote.patientId,
        encounterId: data.currentNote.encounterId,
        claimId: data.currentNote.claimId,
        paymentId: data.currentNote.paymentId,
        insurancePaymentId: data.currentNote.insurancePaymentId,
      };

      await addNote(newNote);
      data.currentNote = null;
      await getAll();
      v$.value.$reset();
    }

    async function updateItem() {
      const result = await v$.value.currentNote.$validate();
      if (!result) return;
      await updateNote(data.currentNote);
      data.currentNote = null;
      await getAll();
    }

    async function updateStatus(note) {
      await updateNote(note);
    }

    async function deleteItem(item) {
      await Swal.fire({
        title: "Do you want to delete this note?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (data.currentNote && data.currentNote.id == item.id)
            data.currentNote = null;
          await deleteNote(item);
          await getAll();
        }
      });
    }

    function selectItem(item) {
      data.currentNote = JSON.parse(JSON.stringify(item));
      data.initNote = item;
    }

    async function cancel() {
      if (
        data.initNote &&
        (data.initNote?.message != data.currentNote.message ||
          data.initNote?.assignedTo != data.currentNote.assignedTo ||
          data.initNote?.dueDate != data.currentNote.dueDate ||
          data.initNote?.status != data.currentNote.status)
      ) {
        await Swal.fire({
          title: "Are you sure you want to leave without saving changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            data.currentNote = null;
            data.initNote = null;
          }
          return;
        });
      } else {
        data.currentNote = null;
        data.initNote = null;
      }
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function goTo(initpath, value) {
      if (value) {
        const path = encodeURIComponent(route.fullPath);
        router.push({
          path: initpath + value,
          query: { breadcrumb: path },
        });
      }
    }

    const maxDueDate = computed(() => {
      return getNow();
    });

    return {
      addCurrentNote,
      addItem,
      organizationId,
      cancel,
      clearSearch,
      deleteItem,
      getAll,
      goTo,
      data,
      selectItem,
      updateItem,
      updateStatus,
      orderList,
      search,
      availableNotesTypes,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      maxDueDate,
      v$,
    };
  },
});

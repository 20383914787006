
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { UserManager, WebStorageStateStore, User } from "oidc-client-ts";
import * as Yup from "yup";
import {
  getUser,
  saveOrganization,
  saveRehab,
} from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      await store.dispatch(Actions.LOGIN, values);

      const errorList = store.getters.getErrors;
      console.log(errorList[0]);

      if (errorList[0] != null) {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          // Activate indicator
          submitButton.value.setAttribute("data-kt-indicator", "off");
        }

        Swal.fire({
          text: "Your email or password is incorrect. If you don't remember your credentials please use the forgot password link.",
          icon: "error",
        });

        return;
      }

      //TODO: AUTH
      const user = store.getters.currentUser;


      if (user && !user.isSuperUser) {
        await store.dispatch("GETORGANIZATIONSFORUSER");
      } else {
        await store.dispatch("GETORGANIZATIONS");
      }

      // set default org
      const orgList = store.getters.allOrganizations;
      saveOrganization(orgList[0].id);
      store.commit("SETORGANIZATION", orgList[0]);
      ApiService.setTenant();
      // and rehab
      await store.dispatch("GETALLREHABS");
      const rehabs = store.getters.allRehabs;
      if (rehabs.length > 0) {
        saveRehab(rehabs[0].rehabId);
        store.commit("SETCURRENTREHAB", rehabs[0]);
        ApiService.setRehab();
      }

      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];

      if (!error) {
        // Swal.fire({
        //   text: "You have successfully logged in!",
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "Ok, got it!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-primary",
        //   },
        // }).then(function () {
        //   // Go to page after successfully login
        //   router.push({ name: "homePage" });
        // });

        router.push({ name: "homePage" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});


import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxValue, maxLength } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import {
  addInsurancePayment,
  getInsurancePayment,
  updateInsurancePayment,
  deleteInsurancePayment,
  postInsurancePayment,
} from "@/api/insurancePayment.api";
import ClaimHistoryPayment from "@/modules/payment/ClaimHistoryPayment.vue";
import ClaimServicePaymentPosting from "@/modules/payment/ClaimServicePaymentPosting.vue";
import ClaimsAlreadyDistributed from "@/modules/payment/ClaimsAlreadyDistributed.vue";
import { PatientInsurance } from "@/modules/patientPayer/patientPayer.model";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import { epsilon, subNumbers, sumNumbers } from "@/utility";
import { getClaimForPayment } from "@/api/claim.api";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";
import InsurancePaymentsSearchComponent from "@/modules/payment/InsurancePaymentsSearch.vue";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  modal;
  savingInProgress: boolean;
  isDirty: boolean;
  isLoaded: boolean;
  payment?: any;
  selectedClaim: any;
  previousPayment?: any;
  createNewMode: boolean;
  unlockedMode: boolean;
  postedForSelectedClaim: number;
  adjustmentForSelectedClaim: number;
  amountFromInsurances: number;
  totalPosted: number;
  totalAdjustment: number;
  dict: {
    waystarList: PatientInsurance[];
    paymentTypes: any[];
  };
}

export default defineComponent({
  name: "InsurancePayment",
  components: {
    Multiselect,
    DecimalInput,
    ClaimHistoryPayment,
    ClaimServicePaymentPosting,
    ClaimsAlreadyDistributed,
    NoteComponent,
    DateFloatComponent,
    InsurancePaymentsSearchComponent,
  },
  props: ["paymentId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const serviceHistoryPayment = ref(null);
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      modal: false,
      savingInProgress: false,
      amountFromInsurances: 0,
      isDirty: false,
      isLoaded: false,
      payment: undefined,
      selectedClaim: null,
      createNewMode: true,
      unlockedMode: false,
      postedForSelectedClaim: 0,
      adjustmentForSelectedClaim: 0,
      totalPosted: 0,
      totalAdjustment: 0,
      dict: {
        waystarList: [],
        paymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
    });

    watch(
      () => data.payment,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPayment &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousPayment)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.waystarList = await searchDictInsuranceCompanys({ search: "" });

      if (props.paymentId) {
        data.payment = await getInsurancePayment(props.paymentId);
        data.createNewMode = false;

        if (data.payment.isPosted) {
          setCurrentPageBreadcrumbs("View Insurance Payment", [
            { buttonTitle: "Back", path: "back" },
          ]);
        } else {
          setCurrentPageBreadcrumbs("Edit Insurance Payment", [
            { buttonTitle: "Back", path: "back" },
          ]);
        }
      } else {
        setCurrentPageBreadcrumbs("Add Insurance Payment", [
          { buttonTitle: "Back", path: "back" },
        ]);

        data.payment = {
          paymentDate: getUTCnow().toISOString(),
          accountingDate: getUTCnow().toISOString(),
          amount: null,
          insurancePaymentUnappliedDistributions: [],
        };

        data.createNewMode = true;
      }
      calculateTotalAmount();
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isLoaded = true;
      data.isDirty = false;
    });

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    const amountValidation = (amount) => amount >= data.totalPosted;

    const paymentRules = {
      payment: {
        payerId: {
          req: helpers.withMessage("Required", required),
        },
        paymentType: {
          req: helpers.withMessage("Required", required),
        },
        paymentDate: {
          paymentReq: helpers.withMessage("Required", required),
        },
        amount: {
          req: helpers.withMessage("Required", required),
          maxValue: maxValue(9999999999),
        },
        totalAmount: {
          minValue: helpers.withMessage(
            "Amount cannot be less than total posted",
            amountValidation
          ),
          maxValue: maxValue(9999999999),
        },
        unappliedCredit: { maxValue: maxValue(9999999999) },
        checkRemittanceNumber: {
          req: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
      },
    };

    let v$ = useVuelidate(paymentRules, data as never) as any;

    const maxServDate = computed(() => {
      return getNow();
    });

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    async function savePayment() {
      let validateResults = await v$.value.$validate();
      if (validateResults) {
        data.savingInProgress = true;
        data.payment.remainingToPost = subNumbers(
          data.payment.totalAmount,
          sumNumbers(data.totalPosted, data.payment.unappliedCredit)
        );

        if (data.createNewMode) {
          data.payment.id = await addInsurancePayment(data.payment);
          data.createNewMode = false;
        } else {
          await updateInsurancePayment(data.payment);
        }
        data.payment = await getInsurancePayment(data.payment.id);
        calculateTotalAmount();
      }
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isDirty = false;
      data.savingInProgress = false;
    }

    function cancel() {
      router.go(-1);
    }

    async function deletePayment() {
      Swal.fire({
        title: "Are you sure you want to delete this Insurance Payment?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteInsurancePayment(data.payment);

          router.go(-1);
        }
      });
    }

    async function postPayment() {
      let validateResults = await v$.value.$validate();
      if (!validateResults) {
        return;
      }

      data.savingInProgress = true;
      data.payment.isPosted = true;
      data.unlockedMode = false;
      await updateInsurancePayment(data.payment);
      await postInsurancePayment(data.payment);
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isDirty = false;
      data.savingInProgress = false;

      let text = "Insurance Payment has been posted.";
      Swal.fire("Ok!", text, "success");
    }

    async function handleClaimSelected(claim) {
      // get full claim from API
      let fullClaim = await getClaimForPayment(claim.id);

      for (let item of fullClaim.claimLineItems) {
        item.currentDistribution = {};
      }

      data.selectedClaim = fullClaim;
    }

    function handlePaymentUpdate(newAmount, adjustment) {
      let tempAmount = subNumbers(
        data.totalPosted,
        data.postedForSelectedClaim
      );
      data.postedForSelectedClaim = newAmount;
      data.totalPosted = sumNumbers(tempAmount, newAmount);

      let tempAdjustment = subNumbers(
        data.totalAdjustment,
        data.adjustmentForSelectedClaim
      );
      data.adjustmentForSelectedClaim = adjustment;
      data.totalAdjustment = sumNumbers(tempAdjustment, adjustment);
    }

    function handleTotalForClaim(amount, adjustment) {
      data.postedForSelectedClaim = amount;
      data.adjustmentForSelectedClaim = adjustment;
    }

    function handleTotalPosted(amount, adjustment) {
      data.totalPosted = amount;
      data.totalAdjustment = adjustment;
    }

    function unlockPayment() {
      data.unlockedMode = true;
    }

    function selectNotes() {
      data.modal = true;
    }

    const remainingToPost = computed(() => {
      return subNumbers(
        data.payment.totalAmount,
        sumNumbers(data.totalPosted, data.payment.unappliedCredit)
      );
    });

    function isPostingDisabled() {
      // if remainingToPost is not in range (-epsilon, epsilon) then it is not zero
      // if a claim is selected, do not allow posting
      return (
        remainingToPost.value > epsilon ||
        remainingToPost.value < -epsilon ||
        data.selectedClaim
      );
    }

    function selectPayment(payment) {
      if (
        data.payment.insurancePaymentUnappliedDistributions.find((i) => {
          return i.insurancePaymentFrom.id == payment.id;
        })
      )
        return;
      let unappliedCreditPayment = {
        insurancePaymentFrom: payment,
        insurancePaymentFromId: payment.id,
        amount: 0,
      };
      data.payment.insurancePaymentUnappliedDistributions.push(
        unappliedCreditPayment
      );
      calculateTotalAmount();
    }

    function removeUnallpiedDistribution(distribution) {
      const index = data.payment.insurancePaymentUnappliedDistributions.indexOf(
        distribution,
        0
      );
      if (index > -1) {
        data.payment.insurancePaymentUnappliedDistributions.splice(index, 1);
      }
      calculateTotalAmount();
    }

    watch(
      () => data.payment?.amount,
      (value) => {
        calculateTotalAmount();
      }
    );

    watch(
      () => data.payment?.insurancePaymentUnappliedDistributions,
      (value) => {
        calculateTotalAmount();
      },
      { deep: true }
    );

    async function amountChanged() {
      await nextTick();
      calculateTotalAmount();
    }

    function calculateTotalAmount() {
      if (!data.payment.insurancePaymentUnappliedDistributions) return;
      data.payment.amountFromInsurances =
        data.payment.insurancePaymentUnappliedDistributions.reduce(function (
          a,
          b
        ) {
          return sumNumbers(a, b.amount);
        },
        0);
      data.payment.totalAmount = sumNumbers(
        data.payment.amountFromInsurances,
        data.payment.amount
      );
    }

    return {
      amountChanged,
      calculateTotalAmount,
      organizationId,
      data,
      maxServDate,
      epsilon,
      getNow,
      cancel,
      savePayment,
      selectPayment,
      subNumbers,
      sumNumbers,
      deletePayment,
      postPayment,
      handleClaimSelected,
      remainingToPost,
      removeUnallpiedDistribution,
      serviceHistoryPayment,
      handlePaymentUpdate,
      handleTotalPosted,
      selectNotes,
      handleTotalForClaim,
      unlockPayment,
      isPostingDisabled,
      v$,
    };
  },
});


import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getPanel } from "@/api/encounter.api";
import { searchClaimsForPayment } from "@/api/claim.api";

export interface Data {
  items: any[];
  payment: any;
  selectedClaim: any;
  keyword: string;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    facilities: { id: string }[];
  };
  postingErrors: string[];
}
export default defineComponent({
  name: "ClaimHistoryPayment",
  props: ["payment"],
  setup(props, ctx) {
    const orderList = [
      {
        name: "Claim ID",
        key: "claimId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Patient",
        key: "patientFull",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Service Date Range",
        key: "dos",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Provider",
        key: "provider",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Remaining Balance",
        key: "remainingBalance",
        IsAdvancedOrderBy: true,
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      payment: undefined,
      items: [],
      selectedClaim: null,
      keyword: "",
      orderBy: "claimId",
      IsAdvancedOrderBy: false,
      isDecr: false,
      panel: {},
      dict: {
        facilities: [],
      },
      postingErrors: [],
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "claimId";
      data.IsAdvancedOrderBy = false;
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.payment = props.payment;

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch() {
      await nextTick();
      await search();
    }

    async function search() {
      await nextTick();
      await getAll();
    }

    async function getAll() {
      if (data.keyword == null || data.keyword == "") {
        data.items = [];
        return;
      }

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        globalSearch: data.keyword,
        currentInsurancePaymentId: data.payment.id,
        isNewDistribution: true,
        hasPayment: false,
      };

      const res = await searchClaimsForPayment(request);
      data.items = res;
      data.items.forEach(function (item) {
        item.remainingBalance = remainingBalance(item);
      });
    }

    function selectClaim(claim) {
      ctx.emit("claimSelected", claim);
    }

    function remainingBalance(claim) {
      if (claim && claim.encounter.services) {
        let sum = 0.0;
        for (let service of claim.encounter.services) {
          if (service && service.balanceDue) {
            sum += service.balanceDue;
          }
        }
        return sum;
      }
      return 0.0;
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      selectClaim,
      remainingBalance
    };
  },
});


import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  watch,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { searchInsurancePayments } from "@/api/insurancePayment.api";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { updateInsurancePayment } from "@/api/insurancePayment.api";
import { searchClaimsForPayment } from "@/api/claim.api";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: any[];
  distributions: any[];
  keyword: string;
  pagination: Pagination;
}
export default defineComponent({
  name: "InsurancePaymentsSearch",
  components: {
    PaginationUi,
  },
  props: ["payerId", "distributed"],
  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      distributions: [],
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
    });

    watch(
      () => props.payerId,
      async (currentValue, oldValue) => {
        await getAll();
      }
    );

    function isAdded(item){
      return props.distributed?.find((i)=>{ return i.insurancePaymentFromId == item.id})
    }

    async function cleanFilter() {
      data.keyword = "";
      await getAll();
    }

    onMounted(async () => {
      await getAll();
    });

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        isPosted: true,
        isUnappliedCredit: true,
        payerId: props.payerId,
      };

      const res = await searchInsurancePayments(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function selectPayment(payment) {
      data.distributions.push(payment);
      ctx.emit("paymentSelected", payment);
    }

    return {
      isAdded,
      organizationId,
      clearSearch,
      data,
      debounceSearch,
      search,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      selectPayment,
      getNow,
    };
  },
});

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1b5b724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-upload" }
const _hoisted_2 = { class: "file-upload__area" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row ms-1" }
const _hoisted_6 = {
  key: 0,
  class: "ab-button",
  for: "files",
  style: {"width":"auto"}
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "row upload-preview mt-2"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "file-extention"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "file",
              name: "",
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileChange($event))),
              id: "files",
              style: {"display":"none"}
            }, null, 32))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args))),
              class: "ab-button",
              disabled: ""
            }, " Select Logo ")),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock("label", _hoisted_6, "Select Logo"))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args))),
              class: "ab-button",
              disabled: _ctx.disabled
            }, " Remove ", 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.data.errors.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.errors, (error, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "file-upload__error",
                      key: index
                    }, [
                      _createElementVNode("span", null, _toDisplayString(error), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.data.file.isUploaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_ctx.data.file.isImage)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.data.file.url,
                  class: "file-image",
                  alt: ""
                }, null, 8, _hoisted_11))
              : _createCommentVNode("", true),
            (!_ctx.data.file.isImage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.data.file.fileExtention), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { getOrganization } from "@/core/services/JwtService";
import PaginationUi from "@/components/ABilling/PaginationXS.vue";
import { helpers, maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useFilters from "@/modules/common/useFilters";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  addOccurrenceSpanCode,
  searchOccurrenceSpanCodes,
  updateOccurrenceSpanCode,
} from "@/api/code-master-occurrence-span-code.api";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  previousSelectedItem: any;
  isDirty: boolean;
  facility?: any;
  items: any[];
  displayInactive: boolean;
  selectedItem: any;
  isAddMode: boolean;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  keyword?: string;
  pagination: Pagination;
}

export default defineComponent({
  name: "Occurrence Span Codes",
  props: ["facility"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  components: { PaginationUi },
  setup(props) {
    let organizationId = ref<string | null>("");
    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "Code",
        key: "code",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Description",
        key: "description",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Active",
        key: "isActive",
        isFilter: true,
        keyword: null,
      },
    ];

    const router = useRouter();
    let data = reactive<Data>({
      previousSelectedItem: null,
      isDirty: false,
      items: [],
      displayInactive: false,
      selectedItem: undefined,
      isAddMode: false,
      keyword: "",
      orderBy: "code",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
    });

    watch(
      () => props.facility,
      async (currentValue, oldValue) => {
        await clearState();
        await selectFacility();
      }
    );

    watch(
      () => data.selectedItem,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousSelectedItem &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousSelectedItem)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    const OccurrenceSpanCodeRules = {
      selectedItem: {
        code: {
          maxLength: maxLength(2),
          required: helpers.withMessage("Required", required),
        },
        description: {
          maxLength: maxLength(100),
          required: helpers.withMessage("Required", required),
        },
      },
    };

    let v$ = useVuelidate(OccurrenceSpanCodeRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.selectedItem = null;
      getAll();
    });

    async function selectFacility() {
      data.keyword = undefined;
      await getAll();
    }

    async function debounceGetAll() {
      await debounce(getAll, useFilters().debounceMs)();
    }

    async function getAll() {
      if (!props.facility) return;
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }
      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        facilityId: props.facility,
        search: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        displayInactive: data.displayInactive,
      };
      const res = await searchOccurrenceSpanCodes(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      v$.value.$reset();
    }

    function selectItem(item) {
      data.isAddMode = true;
      data.selectedItem = JSON.parse(JSON.stringify(item));
      data.previousSelectedItem = JSON.parse(JSON.stringify(item));
      data.isDirty = false;
    }

    async function addItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        data.selectedItem.facilityId = props.facility;
        var codeId = await addOccurrenceSpanCode(data.selectedItem);
        if (codeId != "") {
          clearState();
          await getAll();
          data.isDirty = false;
        } else {
          Swal.fire({
            text: "The code you entered is already in the list.",
            buttonsStyling: false,
            confirmButtonText: "Go Back",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "ab-button",
            },
          });
        }
      }
    }

    async function updateItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        var codeId = await updateOccurrenceSpanCode(data.selectedItem);
        if (codeId != "") {
          const selectedItemInListIndex = data.items.findIndex((item: any) => {
            return item.id == data.selectedItem.id;
          });
          data.items[selectedItemInListIndex] = data.selectedItem;
          clearState();
          await getAll();
          data.isDirty = false;
        } else {
          Swal.fire({
            text: "The code you entered is already in the list.",
            buttonsStyling: false,
            confirmButtonText: "Go Back",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "ab-button",
            },
          });
        }
      }
    }

    async function addMode() {
      v$.value.$reset();
      data.selectedItem = { isActive: true };
      data.previousSelectedItem = JSON.parse(JSON.stringify(data.selectedItem));
      data.isAddMode = true;
    }

    async function clear() {
      if (data.isDirty) {
        let text = "Are you sure you want to leave without saving changes?";
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            await clearState();
            data.isDirty = false;
          }
        });
      } else {
        await clearState();
      }
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;

      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearState() {
      data.selectedItem = null;
      data.isAddMode = false;
      data.previousSelectedItem = null;
      data.isDirty = false;
    }

    return {
      orderList,
      data,
      router,
      selectFacility,
      organizationId,
      clearState,
      props,
      debounceGetAll,
      getAll,
      v$,
      addItem,
      updateItem,
      clear,
      pageChanged,
      pageSizeChanged,
      selectFilter,
      getSortInfo,
      selectItem,
      addMode,
    };
  },
});


import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { uuid } from "vue-uuid";
import useFilters from "@/modules/common/useFilters";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getSaveTransactioniFrame,
  saveTransaction,
} from "@/api/transactions.api";
import { useStore } from "vuex";
import { nexioTransactionMaxValue } from "@/utility";

export interface Transaction {
  id: string;
  paymentId: string;
  transactionId: string;
  amount: string;
  attempts: string;
  status: string;
  transactionType: string;
  transactionDate: any;
  createdDate: any;
}
export interface Data {
  url?: string;
  amount?: number;
  guid?: string;
  isInProgress: boolean;
  isFormValid: boolean;
}

export default defineComponent({
  name: "AddTransactionComponent",
  props: ["patientId"],
  components: { DecimalInput },
  setup(props, ctx) {
    const { orderList } = useFilters();
    const store = useStore();

    const nexio = ref(null);

    let data = reactive<Data>({
      url: undefined,
      amount: 0,
      isInProgress: false,
      isFormValid: false,
    });

    onMounted(async () => {
      window.addEventListener("message", messageListener);
    });

    onUnmounted(async () => {
      window.removeEventListener("message", messageListener);
    });

    async function messageListener(event) {
      const eventData = event.data?.data;
      const eventType = event.data?.event;
      if (event.origin === process.env.VUE_APP_NEXIO_URL) {
        if (eventType === "error") {
          Swal.fire({
            text: eventData.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "ab-button",
            },
          }).then(() => {
            data.guid = undefined;
            data.amount = 0;
            data.url = undefined;
            data.isInProgress = false;
            store.commit("STOPLOADING");
            ctx.emit("transactionCompleted");
          });
        }
        if (eventType === "formValidations") {
          data.isFormValid = eventData.isFormValid;
        }
        if (eventType === "processed") {
          let transaction = {
            PaymentId: eventData.id,
            Amount: eventData.amount,
            CardHolder: eventData.сardHolder,
            TransactionType: eventData.transactionType,
            TransactionDate: eventData.transactionDate,
            Status: eventData.transactionStatus,
            UniqueId: data.guid,
            MerchantId: eventData.merchantId,
          };
          try {
            await saveTransaction({
              clientId: props.patientId,
              transaction: transaction,
            });
          } finally {
            data.guid = undefined;
            data.amount = 0;
            data.url = undefined;
            data.isInProgress = false;
            store.commit("STOPLOADING");
            ctx.emit("transactionCompleted");
          }
        }
      }
    }

    async function addTransaction() {
      data.guid = uuid.v4();
      const url = await getSaveTransactioniFrame({
        clientId: props.patientId,
        uniqueId: data.guid,
        amount: data.amount,
      });
      if (url) {
        data.url = url;
      } else {
        data.guid = undefined;
      }
    }

    function cancel() {
      data.guid = undefined;
      data.amount = 0;
      data.url = undefined;
      data.isInProgress = false;
    }

    async function saveTransactionIframe() {
      data.isInProgress = true;
      store.commit("STARTLOADING");
      await nextTick();
      const saveIframe = window.document.getElementById(
        "saveTransactionIframe"
      );
      if (saveIframe) {
        (saveIframe as any).contentWindow.postMessage(
          "posted",
          process.env.VUE_APP_NEXIO_URL
        );
      }
      return false; // keeps the form from auto submitting
    }

    const isAmountExists = computed(() => {
      return (
        data.amount && data.amount > 0 && data.amount < nexioTransactionMaxValue
      );
    });

    return {
      addTransaction,
      cancel,
      data,
      nexio,
      isAmountExists,
      nexioTransactionMaxValue,
      orderList,
      saveTransactionIframe,
    };
  },
});

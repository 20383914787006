import Swal from "sweetalert2/dist/sweetalert2.js";

export function processErrors(response) {
  let errorsText = "server error";
  if (response.data.errors) {
    errorsText = JSON.stringify(response.data.errors);
  } else if (response.data.messages) {
    errorsText = JSON.stringify(response.data.messages);
  } else if (response) {
    errorsText = JSON.stringify(response);
  }
  Swal.fire({
    text: errorsText,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: "Ok",
    customClass: {
      confirmButton: "ab-button",
    },
  });
}

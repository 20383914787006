import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "row mb-5" }
const _hoisted_3 = {
  class: "row",
  style: {"min-height":"600px"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 1,
  type: "button",
  class: "ab-button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("iframe", {
            id: "saveCardIFrame",
            src: _ctx.data.url
          }, " ", 8, _hoisted_4)
        ]),
        _createElementVNode("div", null, [
          (!_ctx.data.isInProgress)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveCardIframe())),
                type: "button",
                class: "ab-button",
                disabled: !_ctx.data.isFormValid
              }, " Save Card ", 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("button", _hoisted_6, " In progress "))
        ])
      ])
    ])
  ]))
}
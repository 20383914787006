
import { computed, defineComponent, getCurrentInstance, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import { useStore } from "vuex";
import { saveRehab } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const currentOrganization = computed(() => {
      return store.getters.organization;
    });
    const currentRehab = computed(() => {
      return store.getters.selectedRehab;
    });
    let currentRehabId = ref(store.getters.selectedRehab?.id);
    const rehabsList = computed(() => {
      return store.getters.allRehabs;
    });

    watch(
      () => store.getters.selectedRehab, // Vue 2: props.value
      (value) => {
        currentRehabId.value = store.getters.selectedRehab?.id;
      }
    );

    function rehabChanged(event) {
      const selectedId = event.target.value;
      currentRehabId.value = store.getters.selectedRehab?.id;
      Swal.fire({
        title: "Are you sure you want to change the Company?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ///ClaimManagement

          if (
            route.path.includes("/patientRefund") ||
            route.path.endsWith("patientPayment")
          ) {
            router
              .push({ path: "/Payments/PatientTransactionsList" })
              .then((res) => {
                reloadPage(selectedId);
              });
          } else if (route.path.includes("/encounter")) {
            router.push({ path: "/serviceManagement" }).then((res) => {
              reloadPage(selectedId);
            });
          } else if (
            route.path.includes("/claim/") ||
            route.path.endsWith("/claim")
          ) {
            router.push({ path: "/claimManagement" }).then((res) => {
              reloadPage(selectedId);
            });
          } else if (route.path.includes("/patient")) {
            router.push({ path: "/patientAccounts" }).then((res) => {
              reloadPage(selectedId);
            });
          } else if (route.path.includes("/facilityPage")) {
            router.push({ path: "/settings/facilitiesPage" }).then((res) => {
              reloadPage(selectedId);
            });
          } else if (route.path.includes("/providerPage")) {
            router.push({ path: "/settings/providersPage" }).then((res) => {
              reloadPage(selectedId);
            });
          } else if (route.path.includes("/referringProviderPage")) {
            router
              .push({ path: "/settings/referringProvidersPage" })
              .then((res) => {
                reloadPage(selectedId);
              });
          } else if (route.path.includes("/dropdownsPage")) {
            router.push({ path: "/settings/dropdownsPage" }).then((res) => {
              reloadPage(selectedId);
            });
          } else {
            reloadPage(selectedId);
          }
        }
      });
    }

    function reloadPage(selectedId) {
      ctx.emit("rehabChanged", selectedId);
      changeRehab(selectedId);
      window.location.reload();
      //currentRehabId.value = selectedId;
    }

    function changeRehab(rehabId) {
      const rehab = store.getters.allRehabs.find((item) => {
        return item.id == rehabId;
      });
      saveRehab(rehab?.rehabId);
      ApiService.setRehab();
      store.commit("SETCURRENTREHAB", rehab);
    }

    return {
      currentOrganization,
      currentRehab,
      asideTheme,
      rehabsList,
      rehabChanged,
      currentRehabId,
      t,
    };
  },
});
